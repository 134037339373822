import React from "react"
import { graphql } from "gatsby"
import './MainPages.css'
import moment from "moment";
import Link from "gatsby-link"
import Layout from './../components/Layouts';
import { Card, Divider } from 'antd';
import './Index.css'

export const frontmatter = {
  layoutType: `page`,
  path: `/all/`,
}


// const { Meta } = Card;
// const TITLES = ["Deep Learning", "Visualisation", "Bots"]

const ExternalLink = (props) => {
  return (
      <a className="default-link" href={props.item.path} target="_" rel="noopener noreferrer">
        <li style={{flex: "1 0"}}>
          <div>
            {`\u25CD ${props.item.title}`}
          </div>
        </li>
      </a>
  )
}

const InternalLink = (props) => {
  return (
    <div>
      <Link className="default-link" to={props.item.path}><li>{`\u25CD ${props.item.title}`}</li></Link>      
    </div>
  )
}

class ShowAllIndex extends React.Component {
  
  showPost(path) {
    console.log(`showpost: ${path}`)
    return <div />
  }

  addCard(title, items, show_more, show_more_path, color) {
      return (
        <Card
        className="column is-one-third misc-card"
        title={title.toUpperCase()}        
        style={{marginRight: 10, marginBottom: 10, fontSize: '18px', borderRadius: '5px', backgroundColor: color}}        
        headStyle={{borderColor: color}}
        key={title}
        extra={
          show_more 
          ? <Link to={show_more_path}>More</Link>
          : ""
        }
      >
        <div className="all-content-font" style={{color: "#425066"}}>
          <ul style={{fontSize: 16, textAlign: "left" }}>
        {
          items && items.map(item => {          
            return  item.path.startsWith("https") 
              ? <ExternalLink item={item} />
              : <InternalLink item={item} />

          })
        }        
        </ul>
        </div>
      </Card>
      )
  }

  filterPages(items, searchTerm) {
    return items
      .filter(item => item.node.frontmatter.contentType == searchTerm)
      .map(item => {
        return {
          title: item.node.frontmatter.title,
          path: item.node.frontmatter.path,
          written: item.node.frontmatter.written,
          updated: item.node.frontmatter.updated          
        }
      })
  }

  filterJSPages(items, searchTerm) {
    return items
      .filter(item => item.node.data.contentType == searchTerm)
      .map(item => {
        return {
          title: item.node.data.title,
          path: item.node.data.path,
          written: item.node.data.written,
          updated: item.node.data.updated
        }
      })
  }

  getDataVizPages() {
    // Just create a directory with index.md instead of json
    // let jsondata = this.props.data.allDatavizJson.edges.map(item => {
    //   return {
    //     "title": item.node.title,
    //     "path": item.node.path,
    //   }
    // });

    let pages = [
      // ...jsondata,
      ...this.filterMarkdownPages(this.props.data.allMarkdownRemark.edges, "dataviz"),
      ...this.filterJSPages(this.props.data.allJavascriptFrontmatter.edges, "dataviz"),
    ];

    return pages;
  }


  getLatest(items, k) {
    return items.sort((a,b) => {
      let date1 = moment(a.written, "YYYY-MM-DD");
      let date2 = moment(b.written, "YYYY-MM-DD");
      return date2.diff(date1);
    }).slice(0, k);
  }

  render() {
    let mdEdges = this.props.data.allMarkdownRemark.edges;
    let jsEdges = this.props.data.allJavascriptFrontmatter.edges
    console.log(this.props.data.allDatavizJson.edges);

    //Deep learning
    let dlPages = [
      ...this.filterPages(mdEdges, "blog"),
      ...this.filterPages(jsEdges, "blog"),
    ]

    let dvizPages = [
      ...this.filterPages(mdEdges, "dataviz"),
      ...this.filterPages(jsEdges, "dataviz"),
    ]

    let demoPages = [
      ...this.filterPages(mdEdges, "demo"),
      ...this.filterPages(jsEdges, "demo"),
    ]

    let notebookPages = [
      ...this.filterPages(mdEdges, "notebook"),
      ...this.filterPages(jsEdges, "notebook"),
    ]

    let miscPages = [
      ...this.filterPages(mdEdges, "misc"),
      ...this.filterPages(jsEdges, "misc"),
    ]

    // let placeHolderPages = [
    //   ...this.filterPages(mdEdges, "placeholder"),
    //   ...this.filterPages(jsEdges, "placeholder"),
    // ]

    return (
    <Layout data={this.props.data} location={this.props.location}>
    <div className="container" style={{maxWidth: 1200, margin: "40px auto"}}>
      <div className="columns is-multiline is-centered">
      {this.addCard("Artificial Intelligence", this.getLatest(dlPages, 6), true, "/", "rgba(254, 167, 36, 0.6)")}
      {this.addCard("Visualisation", this.getLatest(dvizPages, 6), true, "/viz", "rgba(46, 227, 102, 0.6)")}
      {this.addCard("Demo App", this.getLatest(demoPages, 6), false, null, "rgba(249, 246, 81, 0.6)")}
      {this.addCard("Notebooks", this.getLatest(notebookPages, 6), false, null, "rgba(9, 231, 210, 0.6)")}
      {this.addCard("Others", this.getLatest(miscPages, 6), false, null, "rgba(19, 157, 219, 0.6)")}
      {/* {this.addCard("Placeholder", this.getLatest(placeHolderPages, 6))} */}
      {/* {this.addCard("Bots")}
      {this.addCard("Random")}
      {this.addCard("Reviews")} */}
      </div>
    </div>
    </Layout>
    )
  }
}

export default ShowAllIndex

export const showAllPageQuery = graphql`
  query showAllPosts {
    allJavascriptFrontmatter(sort: { fields: [frontmatter___updated], order: DESC }) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            path
            title
            written
            layoutType
            contentType
            category
            description    
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }              
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___updated], order: DESC }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            path
            layoutType
            contentType
            written
            updated
            category
            description
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }              
            }
          }
          timeToRead
        }
      }
    }
    allDatavizJson {
      edges {
        node {
          title
          path
        }
      }  
    }
  
  }
`
